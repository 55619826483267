import { ArrowRight, Play, Scissors, Upload } from "lucide-react";
import { Footer } from "~/components/footer";
import { Header } from "~/components/header";
import { Button } from "~/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";

export default function LandingPage() {
	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<main className="flex-1">
				<HeroSection />
				<AboutSection />
				<FeaturesSection />
				<HowItWorksSection />
				<BenefitsSection />
				<FAQSection />
			</main>
			<Footer />
		</div>
	);
}

function HeroSection() {
	return (
		<section className="relative w-full min-h-[80vh] flex items-center justify-center overflow-hidden">
			<video
				className="absolute top-0 left-0 w-full h-full object-cover"
				src="/hero-video.mp4"
				autoPlay
				loop
				muted
				playsInline
			/>

			<div className="relative z-10 text-white text-center px-4 max-w-[700px] mx-auto space-y-5">
				<h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
					キリクリでYouTube動画を自動でショートフォーマットに最適化
				</h1>
				<p className="mx-auto md:text-xl text-gray-200">
					AIが魅力的なハイライトシーンを抽出。わずか数クリックで、YouTube
					Shorts、TikTok、Instagram Reels向けの縦型ショート動画へ変換します。
				</p>
				<p className="mx-auto md:text-lg text-gray-300">
					現在、ベータリリース版を無料提供中です。ご利用後、ぜひ{" "}
					<a
						href="https://forms.gle/cgEvjVmXpVHfeb476"
						className="underline underline-offset-4"
						target="_blank"
						rel="noreferrer"
					>
						こちらのフォーム
					</a>{" "}
					からフィードバックをお寄せください。
				</p>
				<div className="space-x-4">
					<Button asChild>
						<a href="/sign-up">ベータ版を試してみる</a>
					</Button>
					<Button asChild>
						<a href="#features">機能を詳しく見る</a>
					</Button>
				</div>
			</div>

			<div className="absolute inset-0 bg-black bg-opacity-40"></div>
		</section>
	);
}

function AboutSection() {
	return (
		<section
			id="about"
			className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-900"
		>
			<div className="container px-4 md:px-6 max-w-3xl mx-auto text-center space-y-6">
				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
					キリクリとは？
				</h2>
				<p className="text-lg text-gray-700 dark:text-gray-200">
					「キリクリ」は、YouTubeなどの長尺動画から、魅力的なハイライトを自動で抽出し、わずか数クリックでショート動画へ最適化するクラウド型ツールです。
					動画編集のスキルがなくても、AIが最適なシーン選定を行い、YouTube
					Shorts、TikTok、Instagram
					Reelsなどに即時対応可能なフォーマットで出力します。
				</p>
				<p className="text-lg text-gray-700 dark:text-gray-200">
					時間短縮・品質均一化により、コンテンツクリエイターやマーケターの生産性を向上し、SNS戦略を強力にサポートします。
				</p>
			</div>
		</section>
	);
}

function FeaturesSection() {
	return (
		<section
			id="features"
			className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800"
		>
			<div className="container px-4 md:px-6">
				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
					主な機能
				</h2>
				<div className="grid gap-6 lg:grid-cols-3 lg:gap-12">
					<Card>
						<CardHeader>
							<CardTitle>AIによるスマート分析</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								高性能なAIが動画を解析し、視聴者を惹きつけるハイライトシーンを自動的に特定します。
							</p>
						</CardContent>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>ハイライト自動抽出</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								複雑な手作業は不要。わずか数分で魅力的なショートクリップを生成します。
							</p>
						</CardContent>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>マルチプラットフォーム対応</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								YouTube Shorts、TikTok、Instagram
								Reelsなど、主要プラットフォーム向けの最適な縦型フォーマットで出力します。
							</p>
						</CardContent>
					</Card>
				</div>
			</div>
		</section>
	);
}

function HowItWorksSection() {
	return (
		<section id="how-it-works" className="w-full py-12 md:py-24 lg:py-32">
			<div className="container px-4 md:px-6">
				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
					使い方は簡単！
				</h2>
				<div className="grid gap-6 lg:grid-cols-3 lg:gap-12">
					<div className="flex flex-col items-center text-center">
						<Upload className="h-12 w-12 mb-4" />
						<h3 className="text-xl font-bold mb-2">
							1. YouTubeリンク & 動画ファイルをアップロード
						</h3>
						<p>
							YouTube動画のURLと同一の動画ファイルをアップロードしてください。これにより、AIがより精度の高い解析を行います。
						</p>
					</div>
					<div className="flex flex-col items-center text-center">
						<Scissors className="h-12 w-12 mb-4" />
						<h3 className="text-xl font-bold mb-2">2. AIが自動編集</h3>
						<p>
							アップロード後、AIが最適なクリップを抽出し、視覚的にも魅力的なショート動画に整えます。
						</p>
					</div>
					<div className="flex flex-col items-center text-center">
						<Play className="h-12 w-12 mb-4" />
						<h3 className="text-xl font-bold mb-2">3. ダウンロード＆投稿</h3>
						<p>
							完成したショート動画をダウンロードし、各種SNSへすぐに投稿できます。
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

function BenefitsSection() {
	return (
		<section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
			<div className="container px-4 md:px-6">
				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
					このサービスを選ぶ理由
				</h2>
				<ul className="grid gap-6 lg:grid-cols-2 lg:gap-12">
					<li className="flex items-start">
						<ArrowRight className="mr-2 h-6 w-6 text-green-500" />
						<span>
							<strong>時間短縮：</strong>
							自動化により編集作業の負担を軽減し、生産性を向上。
						</span>
					</li>
					<li className="flex items-start">
						<ArrowRight className="mr-2 h-6 w-6 text-green-500" />
						<span>
							<strong>品質の安定：</strong>
							一定水準以上のクオリティを保ったクリップを簡単に生成。
						</span>
					</li>
					<li className="flex items-start">
						<ArrowRight className="mr-2 h-6 w-6 text-green-500" />
						<span>
							<strong>マルチSNS展開：</strong>
							主要プラットフォームに対応したフォーマットで時間を節約。
						</span>
					</li>
					<li className="flex items-start">
						<ArrowRight className="mr-2 h-6 w-6 text-green-500" />
						<span>
							<strong>フィードバック歓迎：</strong>
							無料ベータ版で試して改善に協力。正式版では有料プラン導入や利用上限設定を予定。
						</span>
					</li>
				</ul>
			</div>
		</section>
	);
}

function FAQSection() {
	return (
		<section id="faq" className="w-full py-12 md:py-24 lg:py-32">
			<div className="container px-4 md:px-6">
				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
					よくある質問
				</h2>
				<div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
					<Card>
						<CardHeader>
							<CardTitle>
								なぜYouTubeリンクと動画ファイルの両方が必要ですか？
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								動画メタデータと実ファイルを組み合わせることで、AIがより正確にハイライトシーンを特定します。
							</p>
						</CardContent>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>著作権はどうなりますか？</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								元動画の著作権は原著作者に帰属します。生成されたクリップを公開・共有する際は、適切な権利処理と著作権法の遵守をお願いします。
							</p>
						</CardContent>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>
								動画の生成にはどれくらい時間がかかりますか？
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								動画の長さや内容によりますが、平均で10〜30分程度です。ベータ期間中のフィードバックを参考に、今後処理速度や品質を改善していきます。
							</p>
						</CardContent>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>今後の料金体系はどうなりますか？</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								ベータ期間中は無料でご利用いただけますが、正式リリース時には有料プランを導入し、月々の動画処理時間に上限を設ける予定です。また、ベータ期間時に作成されたデータは正式リリース時に削除される場合があります。
							</p>
						</CardContent>
					</Card>
				</div>
			</div>
		</section>
	);
}
